
import Vue from "vue";
import UserContact from "./Contact.vue";
export default Vue.extend({
  name: "PersonWithContacts",
  components: {
    UserContact
  },
  props: {
    user: {
      type: Object,
      default: () => ({})
    }
  }
});
