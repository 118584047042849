
import Vue from "vue";
import UserContact from "./components/Contact.vue";
import PersonWithContacts from "./components/Person.vue";
import {
  atlasAdminDepartment,
  atlasCarriersAddresses,
  atlasPublicContacts,
  atlasContacts
} from "@/helpers/selectOptions";
import { userMapActions } from "@/store/modules/user";
import { IUserModel } from "@/types";
import { cloneDeep } from "lodash";

interface FormattedUser {
  department: keyof typeof atlasAdminDepartment;
  newFormattedUser: Partial<IUserModel>;
}
interface IData {
  formattedUsers: FormattedUser[];
  makingApiRequest: boolean;
  errorMessage: string;
  contactUsString: string;
}
export default Vue.extend({
  name: "ContactUs",
  components: {
    UserContact,
    PersonWithContacts
  },
  data(): IData {
    return {
      formattedUsers: [],
      makingApiRequest: false,
      errorMessage: "",
      contactUsString: "Contact Information"
    };
  },
  mounted: async function() {
    if (this.$isLoggedIn) {
      try {
        this.makingApiRequest = true;
        this.contactUsString = "Loading contact information...";
        const query = {
          atlasAdmin__exists: true,
          deleted: false,
          isBanned: false,
          status: "active",
          isRestricted__exists: false
        };
        const users = await this.getContactUsDetails(query);

        this.formattedUsers = this.groupUsersBaseOnDepartment(users);
        this.contactUsString = this.formattedUsers.length
          ? "Contact Information"
          : "No Contact Information Found";
      } catch (error) {
        this.contactUsString = "No Contact Information Found";
        this.errorMessage = error?.message || "Error fetching contact us";
        this.$bugSnagClient.notify(error);
      } finally {
        this.makingApiRequest = false;
      }
    }
  },
  computed: {
    addresses() {
      return atlasCarriersAddresses.sort((a, b) =>
        a.name.localeCompare(b.name)
      );
    },
    contacts(): any[] {
      if (this.$isLoggedIn) return this.formattedUsers;
      return atlasPublicContacts;
    },
    atlasContactsData(): { label: string; value: string }[] {
      return atlasContacts;
    }
  },
  methods: {
    ...userMapActions(["getContactUsDetails"]),
    groupUsersBaseOnDepartment(users: IUserModel[]): any[] {
      const contactUsData: {
        department: string;
        newUserFormatted: IUserModel[];
      }[] = [];
      const departments = Object.values(atlasAdminDepartment);
      const sortedDepartments = this.sortContactByDepartment(departments);

      sortedDepartments.map((department: string) => {
        const newUserFormatted: IUserModel[] = [];
        const sortedUsers = this.sortPersonsByName(cloneDeep(users));

        sortedUsers.map((user: IUserModel) => {
          if (user.atlasAdmin?.department === department) {
            newUserFormatted.push(user);
          }
        });

        contactUsData.push({
          department,
          newUserFormatted
        });
      });

      return contactUsData.filter(
        (contact: any) => contact.newUserFormatted.length > 0
      );
    },
    sortContactByDepartment(departments: string[]) {
      return departments.sort();
    },
    sortPersonsByName(persons: IUserModel[]): IUserModel[] {
      return persons.sort((a, b) => a.firstName.localeCompare(b.firstName));
    }
  }
});
