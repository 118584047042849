
import Vue from "vue";
export default Vue.extend({
  name: "UserContact",
  props: {
    data: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: ""
    },
    isPhone: Boolean,
    icon: Array,
    default: []
  }
});
